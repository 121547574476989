import React from 'react';

interface IProps {
    size?: number
}

const HomepageBottomBannerIcon: React.FC<IProps> = ({ size = 385 }) => {
    return (
        <svg width={size} height="auto" viewBox="0 0 385 364" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M75.5684 40.9775C75.5684 41.5298 76.0161 41.9775 76.5684 41.9775H257.859C258.411 41.9775 258.859 41.5298 258.859 40.9775V6.08105C258.859 5.52877 258.411 5.08105 257.859 5.08105H76.5684C76.0161 5.08105 75.5684 5.52877 75.5684 6.08105V40.9775Z"
                fill="white"/>
            <path
                d="M110.479 37.5859C118.233 37.5859 124.52 31.2925 124.52 23.5293C124.52 15.766 118.233 9.47266 110.479 9.47266C102.724 9.47266 96.4375 15.766 96.4375 23.5293C96.4375 31.2925 102.724 37.5859 110.479 37.5859Z"
                fill="#197664"/>
            <path
                d="M236.988 18.0293H145.055C144.502 18.0293 144.055 18.477 144.055 19.0293V20.0851C144.055 20.6374 144.502 21.0851 145.055 21.0851H236.988C237.54 21.0851 237.988 20.6374 237.988 20.0851V19.0293C237.988 18.477 237.54 18.0293 236.988 18.0293Z"
                fill="#197664"/>
            <path
                d="M236.988 25.9746H145.055C144.502 25.9746 144.055 26.4223 144.055 26.9746V28.0304C144.055 28.5827 144.502 29.0304 145.055 29.0304H236.988C237.54 29.0304 237.988 28.5827 237.988 28.0304V26.9746C237.988 26.4223 237.54 25.9746 236.988 25.9746Z"
                fill="#197664"/>
            <path
                d="M0 73.677C0 72.5151 0.9868 71.5977 2.14566 71.6823L161.82 83.3421C162.866 83.4185 163.675 84.2888 163.675 85.3368V124.462C163.675 125.582 162.756 126.483 161.638 126.462L1.96282 123.494C0.872932 123.473 0 122.584 0 121.494V73.677Z"
                fill="#585858"/>
            <path
                d="M5.15625 117C5.15625 117.543 5.58919 117.987 6.13177 118L159.077 121.745C159.639 121.758 160.102 121.307 160.102 120.745V88.8171C160.102 88.2908 159.694 87.8546 159.169 87.8193L6.22324 77.5502C5.64589 77.5114 5.15625 77.9693 5.15625 78.5479V117Z"
                fill="white"/>
            <path
                d="M39.1634 84.4094C40.9523 84.5249 42.6943 85.0299 44.2681 85.889C45.8466 86.7528 47.2469 87.9089 48.3946 89.2957C49.5873 90.7304 50.5215 92.362 51.1554 94.1173C51.8242 95.9642 52.1655 97.9138 52.1638 99.8783C52.1709 101.813 51.8292 103.733 51.1554 105.547C50.5304 107.231 49.5937 108.782 48.3946 110.118C47.2432 111.403 45.8381 112.434 44.2681 113.147C42.6653 113.867 40.9196 114.213 39.1634 114.158C37.366 114.101 35.6034 113.648 34.0011 112.831C32.3781 112 30.9317 110.861 29.7421 109.479C28.4939 108.033 27.514 106.376 26.8486 104.585C26.1426 102.694 25.7818 100.691 25.7833 98.672C25.7755 96.683 26.1367 94.7098 26.8486 92.8528C27.5054 91.1378 28.4883 89.5667 29.7428 88.2264C30.9376 86.9493 32.3892 85.94 34.0019 85.2651C35.6346 84.5892 37.4002 84.2964 39.1634 84.4094Z"
                fill="#2F2E41"/>
            <path
                d="M69.6484 96.1082C69.6484 95.5355 70.1286 95.0797 70.7005 95.1096L144.113 98.9396C144.644 98.9673 145.061 99.4062 145.061 99.9382V100.799C145.061 101.37 144.583 101.825 144.012 101.797L70.5999 98.2266C70.0671 98.2007 69.6484 97.7612 69.6484 97.2278V96.1082Z"
                fill="#2F2E41"/>
            <path
                d="M69.6484 104.227C69.6484 103.657 70.1228 103.203 70.6914 103.227L144.104 106.382C144.639 106.405 145.061 106.846 145.061 107.381V108.243C145.061 108.81 144.589 109.264 144.021 109.242L70.609 106.346C70.0725 106.325 69.6484 105.884 69.6484 105.347V104.227Z"
                fill="#2F2E41"/>
            <path
                d="M0 160.886C0 159.796 0.87294 158.907 1.96283 158.886L161.638 155.919C162.756 155.898 163.675 156.799 163.675 157.918V197.044C163.675 198.092 162.866 198.962 161.82 199.039L2.14566 210.699C0.9868 210.783 0 209.866 0 208.704V160.886Z"
                fill="#585858"/>
            <path
                d="M5.15625 203.832C5.15625 204.41 5.64589 204.868 6.22324 204.829L159.169 194.56C159.694 194.525 160.102 194.089 160.102 193.562V161.638C160.102 161.076 159.639 160.625 159.077 160.638L6.13177 164.383C5.58919 164.396 5.15625 164.84 5.15625 165.383V203.832Z"
                fill="white"/>
            <path
                d="M25.795 183.023C25.8745 181.005 26.3159 179.018 27.0982 177.157C27.8329 175.403 28.8772 173.796 30.1812 172.413C31.4222 171.092 32.9103 170.027 34.561 169.28C36.1935 168.544 37.969 168.181 39.759 168.216C41.5157 168.252 43.2413 168.687 44.8055 169.489C46.3435 170.283 47.7022 171.386 48.7972 172.727C49.9392 174.125 50.811 175.723 51.3678 177.441C51.9674 179.286 52.232 181.225 52.1489 183.164C52.0736 185.126 51.6561 187.061 50.9152 188.879C50.2144 190.603 49.2168 192.191 47.9677 193.569C46.7681 194.899 45.3243 195.986 43.7149 196.771C42.1078 197.551 40.3486 197.968 38.5625 197.991C36.7938 198.013 35.0438 197.628 33.4465 196.867C31.8637 196.107 30.4579 195.022 29.3201 193.684C28.1235 192.277 27.2075 190.654 26.6217 188.902C25.9874 187.01 25.7072 185.017 25.795 183.023Z"
                fill="#2F2E41"/>
            <path
                d="M69.6484 177.034C69.6484 176.497 70.0725 176.056 70.609 176.034L144.021 173.139C144.589 173.116 145.061 173.57 145.061 174.138V174.999C145.061 175.535 144.639 175.975 144.104 175.998L70.6914 179.153C70.1228 179.178 69.6484 178.723 69.6484 178.154V177.034Z"
                fill="#2F2E41"/>
            <path
                d="M69.6484 185.152C69.6484 184.619 70.0671 184.179 70.5999 184.153L144.012 180.583C144.583 180.555 145.061 181.011 145.061 181.582V182.442C145.061 182.975 144.644 183.413 144.113 183.441L70.7005 187.271C70.1286 187.301 69.6484 186.845 69.6484 186.272V185.152Z"
                fill="#2F2E41"/>
            <g clipPath="url(#clip0_427_114)">
                <path
                    d="M316.051 328.901L314.394 320.01L304.27 321.488L304.81 329.827C305.569 331.043 306.634 332.037 307.899 332.71C309.164 333.383 310.584 333.71 312.016 333.66C317.336 333.744 316.051 328.901 316.051 328.901Z"
                    fill="#545454"/>
                <path
                    d="M273.117 323.254L272.505 330.476L269.971 335.186L261.96 332.518L260.819 327.159L261.288 318.833L273.117 323.254Z"
                    fill="#545454"/>
                <path
                    d="M240.426 335.522C243.188 334.224 251.739 331.737 258.092 327.183C258.421 326.939 258.796 326.764 259.195 326.669C259.593 326.574 260.007 326.561 260.41 326.63L260.843 326.715C260.984 327.913 261.468 329.045 262.237 329.974C263.006 330.904 264.028 331.591 265.178 331.953C267.916 332.879 272.504 330.475 272.504 330.475C272.504 330.475 273.427 338.301 271.963 340.725C270.5 343.149 238.985 340.725 238.985 340.725C238.794 340.607 238.63 340.452 238.502 340.268C238.373 340.084 238.285 339.875 238.24 339.656C238.24 339.656 236.907 337.18 240.426 335.522Z"
                    fill="#545454"/>
                <path
                    d="M306.97 330.344C310.958 326.583 314.5 329.755 314.5 329.755L316.014 328.902C318.199 332.194 322.427 336.111 322.955 338.07C323.964 341.735 322.175 346 316.266 346C310.357 346 304.965 342.479 304.352 339.62C303.98 337.901 304.581 333.275 304.773 329.827L306.97 330.344Z"
                    fill="#545454"/>
                <path
                    d="M298.16 184.188C296.478 176.778 301.119 169.408 308.525 167.726C315.931 166.043 323.298 170.687 324.979 178.096C326.661 185.506 322.02 192.877 314.614 194.559C307.208 196.241 299.841 191.598 298.16 184.188Z"
                    fill="#D7D7D7"/>
                <path
                    d="M323.438 252.77C323.438 252.77 323.436 321.5 319.835 327.147C316.233 332.794 303.502 330 301.328 327.46C299.154 324.919 300.127 252.77 300.127 252.77H323.438Z"
                    fill="#D7D7D7"/>
                <path d="M300.126 252.77L297.808 181.156H325.322L323.437 252.77H300.126Z" fill="#D7D7D7"/>
                <path
                    d="M300.426 255.45C298.952 249.178 302.84 242.897 309.109 241.423C315.379 239.948 321.656 243.837 323.13 250.11C324.604 256.383 320.716 262.663 314.447 264.138C308.178 265.613 301.9 261.723 300.426 255.45Z"
                    fill="#D7D7D7"/>
                <path
                    d="M280.829 194.778C273.311 193.702 268.089 186.732 269.164 179.21C270.239 171.689 277.206 166.463 284.723 167.539C292.241 168.615 297.464 175.585 296.389 183.107C295.314 190.628 288.347 195.854 280.829 194.778Z"
                    fill="#D7D7D7"/>
                <path
                    d="M286.375 253.334C286.375 253.334 278.5 325 276.335 327.123C274.17 329.246 260.728 328.651 257.864 325.826C255 323 263.148 251.328 263.148 251.328L286.375 253.334Z"
                    fill="#D7D7D7"/>
                <path d="M263.185 250.979L269.106 179.582L296.428 182.742L286.34 253.659L263.185 250.979Z"
                      fill="#D7D7D7"/>
                <path
                    d="M263.148 251.316C262.95 253.616 263.438 255.923 264.55 257.945C265.663 259.968 267.35 261.615 269.398 262.677C271.447 263.74 273.764 264.171 276.057 263.915C278.351 263.66 280.517 262.729 282.281 261.242C284.045 259.754 285.329 257.776 285.97 255.558C286.61 253.34 286.578 250.982 285.879 248.782C285.179 246.582 283.843 244.639 282.039 243.2C280.236 241.76 278.046 240.888 275.746 240.694C272.669 240.434 269.614 241.406 267.252 243.398C264.89 245.389 263.414 248.237 263.148 251.316Z"
                    fill="#D7D7D7"/>
                <path
                    d="M268.961 167.397H325.322V181.252C325.3 184.886 323.841 188.364 321.265 190.926C318.689 193.488 315.204 194.926 311.571 194.926H282.748C282.219 194.928 281.689 194.899 281.163 194.842C277.828 194.454 274.75 192.86 272.509 190.359C270.268 187.858 269.019 184.623 268.997 181.264L268.961 167.397Z"
                    fill="#D7D7D7"/>
                <path
                    d="M268.961 167.397H325.322V181.252C325.3 184.886 323.841 188.364 321.265 190.926C318.689 193.488 315.204 194.926 311.571 194.926H282.748C282.219 194.928 281.689 194.899 281.163 194.842C277.828 194.454 274.75 192.86 272.509 190.359C270.268 187.858 269.019 184.623 268.997 181.264L268.961 167.397Z"
                    fill="#D7D7D7"/>
                <path
                    d="M261.115 102.985C259.859 97.9299 262.937 92.8128 267.99 91.556C273.043 90.2993 278.157 93.3788 279.413 98.4343C280.669 103.49 277.591 108.607 272.538 109.864C267.485 111.12 262.371 108.041 261.115 102.985Z"
                    fill="#197664"/>
                <path
                    d="M321.811 105.159C320.628 100.086 323.78 95.014 328.85 93.8306C333.921 92.6473 338.99 95.8006 340.173 100.874C341.356 105.947 338.204 111.019 333.133 112.202C328.063 113.386 322.993 110.232 321.811 105.159Z"
                    fill="#197664"/>
                <path
                    d="M329.236 108.496L323.039 139.112L327.771 177.851C327.771 177.851 297.747 189.218 267.014 179.666C267.014 179.666 268.624 134.931 271.506 99.8567L263.736 93.9089C270.509 87.6247 294.336 79.2857 300.966 80.3431C311.558 81.9652 337.655 94.2934 338.436 97.2373L329.236 108.496Z"
                    fill="#197664"/>
                <path
                    d="M307.573 82.9746L309.975 59.9644L292.441 68.8921L292.609 69.7332L293.101 81.3044C293.233 84.6207 293.101 87.9611 296.584 88.5139C300.715 89.1867 307.188 86.7836 307.573 82.9746Z"
                    fill="#FBC0A4"/>
                <path
                    d="M292.884 76.9185L292.583 69.709L292.415 68.8679L309.949 59.9402L308.832 70.7544C305.698 75.5247 299.513 76.9305 292.884 76.9185Z"
                    fill="#F69D7D"/>
                <path
                    d="M280.73 50.4479C280.814 44.9687 280.478 37.0743 282.675 31.3428C283.49 28.8915 285.128 26.7982 287.311 25.419C289.713 23.6888 293.064 22.5593 298.12 22.1628C308.676 21.3216 315.534 25.2748 320.121 32.1479C323.544 37.2786 322.079 43.9954 321.322 46.4226C323.28 46.6029 325.994 47.7804 325.37 53.1274C324.421 61.2861 318.512 61.4544 316.771 61.8028C316.771 61.8028 313.516 76.3539 295.574 74.9601C277.631 73.5663 280.55 59.664 280.73 50.4479Z"
                    fill="#FBC0A4"/>
                <path
                    d="M280.141 32.4482C275.337 28.3268 272.491 22.5592 274.221 15.8784C276.827 17.08 279.289 18.0172 281.883 19.1948C279.156 16.7315 278.568 14.4966 278.76 11C278.76 11 311.078 17.9331 317.948 23.184C319.611 24.3164 320.922 25.8947 321.731 27.738L326.642 21.7301C326.642 21.7301 329.585 29.0237 323.784 31.4989C325.302 32.5971 326.359 34.2198 326.751 36.0529C328.396 42.241 323.784 47.2757 323.784 47.2757C323.177 46.9012 322.492 46.6708 321.783 46.602C321.073 46.5333 320.356 46.6281 319.689 46.8791C317.599 47.6121 315.269 51.325 315.389 54.9898L313.48 54.7374L313.72 47.0473C313.72 47.0473 311.186 45.1849 309 38.7324C305.695 40.4397 301.962 41.1375 298.264 40.7391L304.269 34.9475C299.598 38.5937 293.859 40.6033 287.936 40.667C281.282 40.9914 274.209 38.1076 270.81 32.3641C273.644 32.4842 277.271 32.28 280.141 32.4482Z"
                    fill="#545454"/>
                <path
                    d="M293.089 56.7201C292.976 56.8034 292.895 56.9222 292.858 57.0573C292.821 57.1924 292.83 57.336 292.885 57.4651C292.96 57.6836 293.08 57.884 293.238 58.0536C293.395 58.2232 293.585 58.3583 293.797 58.4504C294.001 58.5569 294.225 58.6194 294.455 58.6339C294.685 58.6484 294.915 58.6145 295.13 58.5345C295.467 58.3903 295.623 58.15 295.587 57.9217C295.467 57.4531 294.986 57.1647 294.53 56.9244C294.317 56.7919 294.079 56.7053 293.831 56.6702C293.583 56.635 293.33 56.652 293.089 56.7201Z"
                    fill="#F69D7D"/>
                <path
                    d="M298.119 41.9886C299.681 41.1956 304.568 41.7843 307.871 43.5627C307.848 44.0173 307.673 44.451 307.375 44.795C307.077 45.1389 306.672 45.3734 306.226 45.4612C305.341 45.6333 304.429 45.6005 303.56 45.365C302.01 45.0767 300.593 44.6081 299.104 44.2235C298.873 44.17 298.658 44.0634 298.476 43.9123C298.293 43.7611 298.148 43.5694 298.053 43.3526C297.957 43.1357 297.914 42.8995 297.925 42.6627C297.937 42.426 298.003 42.1952 298.119 41.9886Z"
                    fill="#545454"/>
                <path
                    d="M292.271 41.9166C290.796 41.4388 289.238 41.2763 287.696 41.4399C286.155 41.6034 284.665 42.0892 283.323 42.8658C283.343 43.3081 283.498 43.7338 283.766 44.0859C284.034 44.4381 284.404 44.6999 284.825 44.8364C285.608 45.0504 286.432 45.0752 287.227 44.9085C288.596 44.7163 289.833 44.3678 291.13 44.0914C291.365 44.0529 291.587 43.9603 291.78 43.8209C291.973 43.6816 292.131 43.4993 292.242 43.2885C292.352 43.0777 292.413 42.844 292.418 42.6059C292.423 42.3679 292.372 42.1319 292.271 41.9166Z"
                    fill="#545454"/>
                <path
                    d="M305.014 49.9553C305.047 50.4363 304.936 50.9164 304.693 51.333C304.451 51.7497 304.088 52.0838 303.653 52.2917C303.219 52.4996 302.731 52.5719 302.255 52.499C301.778 52.4262 301.335 52.2116 300.982 51.8832C300.629 51.5548 300.383 51.1278 300.276 50.6576C300.169 50.1875 300.205 49.696 300.381 49.247C300.557 48.798 300.864 48.4123 301.261 48.14C301.659 47.8677 302.13 47.7215 302.612 47.7203C303.225 47.7039 303.82 47.9289 304.269 48.3468C304.718 48.7647 304.986 49.3422 305.014 49.9553Z"
                    fill="#545454"/>
                <path
                    d="M292.56 49.9553C292.581 50.4201 292.464 50.8807 292.222 51.2785C291.981 51.6763 291.627 51.9931 291.205 52.1887C290.783 52.3842 290.312 52.4496 289.853 52.3766C289.394 52.3035 288.966 52.0952 288.626 51.7783C288.285 51.4614 288.047 51.0503 287.941 50.5972C287.834 50.1442 287.866 49.6698 288.03 49.2345C288.194 48.7992 288.484 48.4227 288.863 48.153C289.243 47.8834 289.693 47.7327 290.158 47.7203C290.771 47.7039 291.366 47.9289 291.815 48.3468C292.265 48.7647 292.532 49.3422 292.56 49.9553Z"
                    fill="#545454"/>
                <path
                    d="M281.126 57.8616C281.087 58.7348 281.307 59.6002 281.759 60.3483C282.211 61.0964 282.875 61.6937 283.666 62.0647C284.457 62.4357 285.34 62.5638 286.204 62.4328C287.068 62.3018 287.873 61.9175 288.519 61.3286C289.165 60.7396 289.621 59.9724 289.831 59.1239C290.041 58.2754 289.995 57.3837 289.699 56.5613C289.403 55.739 288.869 55.0229 288.167 54.5037C287.464 53.9845 286.623 53.6853 285.75 53.644C284.578 53.5918 283.434 54.0064 282.567 54.797C281.701 55.5875 281.182 56.6895 281.126 57.8616Z"
                    fill="#F9B092"/>
                <path
                    d="M303.549 58.4504C303.505 59.3244 303.721 60.1919 304.17 60.943C304.619 61.6941 305.281 62.295 306.071 62.6697C306.861 63.0444 307.745 63.176 308.61 63.0479C309.476 62.9197 310.283 62.5376 310.932 61.9498C311.58 61.3621 312.039 60.5952 312.251 59.7462C312.463 58.8972 312.419 58.0043 312.123 57.1806C311.828 56.3568 311.295 55.6393 310.592 55.1189C309.888 54.5984 309.047 54.2984 308.173 54.2569C307.594 54.2262 307.014 54.3105 306.467 54.505C305.921 54.6995 305.418 55.0004 304.988 55.3902C304.558 55.78 304.21 56.2512 303.963 56.7764C303.716 57.3016 303.576 57.8706 303.549 58.4504Z"
                    fill="#F9B092"/>
                <path
                    d="M323.064 50.1474C323.689 52.3103 322.8 54.5933 321.923 56.66C321.729 57.3046 321.349 57.8773 320.83 58.3062C320.7 58.4086 320.55 58.4831 320.39 58.5249C320.23 58.5666 320.063 58.5748 319.899 58.5488C319.736 58.5229 319.579 58.4634 319.44 58.3741C319.3 58.2847 319.181 58.1675 319.089 58.0298C318.813 57.441 319.221 56.8282 319.545 56.2274C319.87 55.6266 320.05 54.8216 319.545 54.4731C319.293 54.3169 318.957 54.3289 318.693 54.1847C318.116 53.8844 318.116 53.0553 318.272 52.4184C318.475 51.5315 318.802 50.6779 319.245 49.8831C319.409 49.4693 319.68 49.1066 320.031 48.8321C320.381 48.5576 320.798 48.3813 321.239 48.321C322.127 48.3451 322.8 49.2583 323.064 50.1474Z"
                    fill="#F9B092"/>
                <path
                    d="M292.824 61.9228C292.704 62.115 293.04 62.5956 293.857 62.9922C294.954 63.4728 296.189 63.5327 297.328 63.1604C298.473 62.8085 299.445 62.0415 300.054 61.0096C300.498 60.2165 300.486 59.6398 300.282 59.5436C299.862 59.3514 298.781 60.9375 296.811 61.5383C294.842 62.1391 293.052 61.4662 292.824 61.9228Z"
                    fill="#F69D7D"/>
                <path
                    d="M307.955 79.2617C307.955 79.2617 299.032 89.1748 296.558 88.5139C294.084 87.853 292.955 79.0935 292.955 79.0935C292.955 79.0935 287.527 80.2951 286.951 81.3284C286.374 82.3618 286.014 92.8155 287.827 94.726C289.641 96.6366 295.597 91.2775 295.597 91.2775C295.597 91.2775 300.702 97.8982 303.548 97.2854C306.394 96.6726 312.915 84.068 313.336 82.53C313.756 80.992 307.955 79.2617 307.955 79.2617Z"
                    fill="white"/>
                <path
                    d="M273.62 136.962C274.725 129.139 277.043 112.557 279.24 102.428C279.453 101.645 279.558 100.837 279.553 100.025C279.536 97.7553 278.701 95.5678 277.202 93.864C275.704 92.1601 273.641 91.054 271.393 90.7488C269.145 90.4435 266.863 90.9596 264.964 92.2022C263.066 93.4448 261.679 95.3306 261.058 97.5137C258.656 105.36 257.035 117.472 254.993 131.675C260.013 134.642 271.95 136.709 273.62 136.962Z"
                    fill="#197664"/>
                <path
                    d="M322.728 134.763C322.728 134.763 321.935 115.621 321.707 104.723C321.495 103.94 321.39 103.131 321.395 102.32C321.409 100.049 322.242 97.8599 323.739 96.1538C325.237 94.4478 327.3 93.3396 329.549 93.0328C331.797 92.726 334.081 93.2411 335.981 94.4837C337.881 95.7262 339.269 97.6126 339.89 99.7967C342.244 107.643 343.168 119.875 343 135.063C336.245 135.454 329.469 135.354 322.728 134.763Z"
                    fill="#197664"/>
                <path
                    d="M259.246 133.561C259.246 141.407 260.375 147.896 262.452 149.866C265.455 152.702 274.042 152.161 284.07 150.551C294.098 148.941 314.094 146.118 314.094 146.118C314.094 146.118 299.13 137.851 286.856 135.556C282.61 134.69 278.261 134.447 273.946 134.835C272.079 134.962 270.241 135.367 268.493 136.036C265.363 135.4 262.275 134.573 259.246 133.561Z"
                    fill="#FBC0A4"/>
                <path
                    d="M306.828 147.115L300.475 148.028C291.912 144.015 274.054 138.343 274.486 134.811L280.323 134.762L306.828 147.115Z"
                    fill="#F69D7D"/>
                <path
                    d="M268.469 135.964C268.469 135.964 304.018 146.045 314.106 150.311C326.536 155.574 332.048 153.543 335.051 148.412C337.453 144.387 337.777 142.405 338.521 135.195C333.254 135.403 327.978 135.258 322.729 134.762C322.729 134.762 304.21 132.095 292.26 131.158C276.516 129.812 270.943 132.419 268.469 135.964Z"
                    fill="#FBC0A4"/>
            </g>
            <defs>
                <clipPath id="clip0_427_114">
                    <rect width="105" height="335" fill="white" transform="matrix(-1 0 0 1 343 11)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default HomepageBottomBannerIcon;