import React from 'react';

const AnalyticsNavIcon = () => {
    return (
        <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666667 2C0.666667 2.73638 1.26362 3.33333 2 3.33333C2.73638 3.33333 3.33333 2.73638 3.33333 2C3.33333 1.26362 2.73638 0.666667 2 0.666667C1.26362 0.666667 0.666667 1.26362 0.666667 2ZM2 22H1.75V22.25H2V22ZM29.6667 22C29.6667 22.7364 30.2636 23.3333 31 23.3333C31.7364 23.3333 32.3333 22.7364 32.3333 22C32.3333 21.2636 31.7364 20.6667 31 20.6667C30.2636 20.6667 29.6667 21.2636 29.6667 22ZM1.75 2V22H2.25V2H1.75ZM2 22.25H31V21.75H2V22.25Z" fill="#534545"/>
            <path d="M26.8681 3.84216L24.5212 4.69127L26.2392 7.01108L26.8681 3.84216ZM16.8822 17.7273L25.6778 5.85117L25.3802 5.44937L16.5846 17.3255L16.8822 17.7273Z" fill="black"/>
            <path d="M16.9285 17.5267L13.2254 10.6846L2.11621 20.9477" stroke="black" strokeWidth="0.5"/>
        </svg>
    );
};

export default AnalyticsNavIcon;
