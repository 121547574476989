import React from 'react';

const ReviewNavIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6823 19.0656H0V15.1061C0 13.3401 3.5738 11.8955 7.9516 11.8955C12.2101 11.8955 15.6934 13.3056 15.6934 15.0264L15.6823 19.0656Z" fill="#534545"/>
            <path d="M7.84653 11.0989C10.3555 11.0989 12.3893 8.7805 12.3893 5.92056C12.3893 3.06062 10.3555 0.742188 7.84653 0.742188C5.3376 0.742188 3.30371 3.06062 3.30371 5.92056C3.30371 8.7805 5.3376 11.0989 7.84653 11.0989Z" fill="#534545"/>
            <path d="M22.2898 22.9266H6.60742V19.2041C6.60742 17.5437 10.1812 16.1855 14.559 16.1855C18.8176 16.1855 22.3008 17.5113 22.3008 19.1291L22.2898 22.9266Z" fill="#37BD80"/>
            <path d="M14.1519 14.8372C16.6521 14.8372 18.6789 12.5736 18.6789 9.78139C18.6789 6.98915 16.6521 4.72559 14.1519 4.72559C11.6518 4.72559 9.625 6.98915 9.625 9.78139C9.625 12.5736 11.6518 14.8372 14.1519 14.8372Z" fill="#37BD80"/>
        </svg>
    );
};

export default ReviewNavIcon;
