import React from 'react';

const TelegramContactIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5996 3.6001C12.5596 3.6001 3.59961 12.5601 3.59961 23.6001C3.59961 34.6401 12.5596 43.6001 23.5996 43.6001C34.6396 43.6001 43.5996 34.6401 43.5996 23.6001C43.5996 12.5601 34.6396 3.6001 23.5996 3.6001ZM32.8796 17.2001C32.5796 20.3601 31.2796 28.0401 30.6196 31.5801C30.3396 33.0801 29.7796 33.5801 29.2596 33.6401C28.0996 33.7401 27.2196 32.8801 26.0996 32.1401C24.3396 30.9801 23.3396 30.2601 21.6396 29.1401C19.6596 27.8401 20.9396 27.1201 22.0796 25.9601C22.3796 25.6601 27.4996 21.0001 27.5996 20.5801C27.6135 20.5165 27.6117 20.4504 27.5942 20.3877C27.5768 20.325 27.5443 20.2674 27.4996 20.2201C27.3796 20.1201 27.2196 20.1601 27.0796 20.1801C26.8996 20.2201 24.0996 22.0801 18.6396 25.7601C17.8396 26.3001 17.1196 26.5801 16.4796 26.5601C15.7596 26.5401 14.3996 26.1601 13.3796 25.8201C12.1196 25.4201 11.1396 25.2001 11.2196 24.5001C11.2596 24.1401 11.7596 23.7801 12.6996 23.4001C18.5396 20.8601 22.4196 19.1801 24.3596 18.3801C29.9196 16.0601 31.0596 15.6601 31.8196 15.6601C31.9796 15.6601 32.3596 15.7001 32.5996 15.9001C32.7996 16.0601 32.8596 16.2801 32.8796 16.4401C32.8596 16.5601 32.8996 16.9201 32.8796 17.2001Z" fill="#f5fbfc"/>
        </svg>
    );
};

export default TelegramContactIcon;